// src/pages/FlowFormComponents/RenderFields/SaveAssetFields.js

import React, { useState } from "react";
import { Label } from "../../../components/ui/label";
import { Textarea } from "../../../components/ui/textarea";
import { Card, CardContent } from "../../../components/ui/card";
import { Input } from "../../../components/ui/input";
import { savableAssets } from './constants';

// Import subcomponents
import SavedBlogPostFields from './SaveAssetFieldsComponents/SavedBlogPostFields';
import SavedProductFields from './SaveAssetFieldsComponents/SavedProductFields';
import SavedCourseFields from './SaveAssetFieldsComponents/SavedCourseFields';

const SaveAssetFields = ({ step, handleFieldChange, index }) => {
  const handleTagsChange = (e) => {
    const tagsString = e.target.value;
    const tagsArray = tagsString.split(",").map((tag) => tag.trim());
    handleFieldChange("assetTags", tagsArray);
  };

  const handleImageUrlsChange = (e) => {
    const urlsString = e.target.value;
    const urlsArray = urlsString.split(",").map((url) => url.trim());
    handleFieldChange("imageUrls", urlsArray);
  };

  return (
    <Card>
      <CardContent className="pt-6 space-y-4">
        {/* Common Fields */}
        <div>
          <Label
            htmlFor={`asset-type-${index}`}
            className="text-inter font-bold"
          >
            Asset Type
          </Label>
          <select
            id={`asset-type-${index}`}
            value={step.assetType || ""}
            onChange={(e) => handleFieldChange("assetType", e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select Asset Type</option>
            {savableAssets.map((asset) => (
              <option key={asset.value} value={asset.value}>
                {asset.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <Label
            htmlFor={`asset-data-${index}`}
            className="text-inter font-bold"
          >
            {step.assetType === 'blogPost' ? 'Blog Post Content' : 'Main Content'}*
          </Label>
          <Textarea
            id={`asset-data-${index}`}
            placeholder={`{ text: "This is an example of a saved general text. It can be used for debugging purposes." }
or
{ text: "This is an example of a saved general text. It can be used for debugging purposes.",
  title: "Example Record for Debugging",
  tags: ["debug", "example", "mongoose"] }
or
{{step1_outputKey}} <- Notice the double brackets`}
            value={step.assetData || ""}
            onChange={(e) => handleFieldChange("assetData", e.target.value)}
            className="min-h-[200px]"
            required
          />
          <p className="mt-1 text-xs text-gray-500">
            Enter the asset data in JSON format. You can use placeholders like
            &#123;&#123;stepX_outputKey&#125;&#125; to reference outputs from
            previous steps.
          </p>
        </div>
        <div>
          <Label
            htmlFor={`image-urls-${index}`}
            className="text-inter font-bold"
          >
            Image URLs (Optional)
          </Label>
          <Input
            id={`image-urls-${index}`}
            placeholder="Enter image URLs separated by commas"
            value={
              Array.isArray(step.imageUrls)
                ? step.imageUrls.join(", ")
                : step.imageUrls || ""
            }
            onChange={handleImageUrlsChange}
          />
          <p className="mt-1 text-xs text-gray-500">
            Enter image URLs separated by commas, e.g.,
            "http://example.com/image1.jpg, http://example.com/image2.jpg"
          </p>
        </div>
        <div>
          <Label
            htmlFor={`asset-title-${index}`}
            className="text-inter font-bold"
          >
            Title (Optional)
          </Label>
          <Input
            id={`asset-title-${index}`}
            placeholder="Enter asset title"
            value={step.assetTitle || ""}
            onChange={(e) => handleFieldChange("assetTitle", e.target.value)}
          />
        </div>
        <div>
          <Label
            htmlFor={`asset-tags-${index}`}
            className="text-inter font-bold"
          >
            Tags (Optional)
          </Label>
          <Input
            id={`asset-tags-${index}`}
            placeholder="Enter tags separated by commas"
            value={
              Array.isArray(step.assetTags)
                ? step.assetTags.join(", ")
                : step.assetTags || ""
            }
            onChange={handleTagsChange}
          />
          <p className="mt-1 text-xs text-gray-500">
            Enter tags separated by commas, e.g., "tag1, tag2, tag3"
          </p>
        </div>
        <div>
          <Label
            htmlFor={`asset-note-${index}`}
            className="text-inter font-bold"
          >
            Note (Optional)
          </Label>
          <Textarea
            id={`asset-note-${index}`}
            placeholder="Enter a note for this asset"
            value={step.note || ""}
            onChange={(e) => handleFieldChange("note", e.target.value)}
            className="min-h-[100px]"
          />
        </div>
        <div>
          <Label
            htmlFor={`output-key-${index}`}
            className="text-inter font-bold lg:text-lg"
          >
            Output Name (used to reference this response in later steps)
          </Label>
          <Input
            id={`output-key-${index}`}
            placeholder="e.g., savedAssetId"
            value={step.outputKey || ""}
            onChange={(e) => handleFieldChange("outputKey", e.target.value)}
            required
          />
        </div>

        {/* Model-specific Fields */}
        {step.assetType === 'blogPost' && (
          <SavedBlogPostFields
            step={step}
            handleFieldChange={handleFieldChange}
            index={index}
          />
        )}
        {step.assetType === 'product' && (
          <SavedProductFields
            step={step}
            handleFieldChange={handleFieldChange}
            index={index}
          />
        )}
        {step.assetType === 'course' && (
          <SavedCourseFields
            step={step}
            handleFieldChange={handleFieldChange}
            index={index}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default SaveAssetFields;