import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserTeams } from '../../slices/teamsSlice';
import { addTeamToRecord, removeTeamFromRecord } from '../../services/api';
import { useToast } from "../ui/use-toast";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import { Badge } from "../ui/badge";
import { Users, X, Plus, Share2, Shield, Gem } from 'lucide-react';

const TeamShareDialog = ({ 
  open, 
  onOpenChange, 
  assetId, 
  modelName, 
  currentTeams = [],
  onShareSuccess 
}) => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [selectedTeam, setSelectedTeam] = useState('');
  const [loading, setLoading] = useState(false);
  const { teams } = useSelector((state) => state.teams);
  const [sharedTeams, setSharedTeams] = useState(currentTeams);

  useEffect(() => {
    dispatch(fetchUserTeams());
  }, [dispatch]);

  useEffect(() => {
    setSharedTeams(currentTeams);
  }, [currentTeams]);

  const handleAddTeam = async () => {
    if (!selectedTeam) return;
  
    setLoading(true);
    try {
      await addTeamToRecord(modelName, assetId, selectedTeam);
      
      // Find the full team object
      const newTeam = teams.find(t => t._id === selectedTeam);
  
      // Update state with the full team object instead of just the ID
      setSharedTeams(prev => [...prev, newTeam]);
      
      toast({
        title: "Team Added",
        description: `Successfully shared with ${newTeam?.name}`,
      });
  
      setSelectedTeam('');
      if (onShareSuccess) {
        await onShareSuccess(); // Wait for callback to complete
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to share with team",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveTeam = async (teamId) => {
    setLoading(true);
    try {
      await removeTeamFromRecord(modelName, assetId, teamId);
      
      // Filter using the team's _id property
      setSharedTeams(prev => prev.filter(team => team._id !== teamId));
      
      if (onShareSuccess) {
        await onShareSuccess(); // Wait for callback to complete
      }
  
      toast({
        title: "Team Removed",
        description: "Successfully removed team access",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to remove team",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const availableTeams = teams.filter(team => !sharedTeams.includes(team._id));

  return (
    <div>
    <Dialog open={open} onOpenChange={onOpenChange} modal={true}>
      <DialogContent 
        className="sm:max-w-[425px] w-[95vw] mx-auto h-[80vh] lg:h-fit lg:max-h-[90vh] overflow-hidden flex flex-col z-[60] bg-gray-100 border-none shadow-xl rounded-lg" 
        onClick={(e) => e.stopPropagation()}
      >
        <DialogHeader className="space-y-3 px-1">
          <DialogTitle className="flex items-center gap-2 text-lg">
            <Share2 className="h-5 w-5" />
            Share with Teams
          </DialogTitle>
          <DialogDescription className="text-sm">
            Share this asset with your teams to allow team members access.
          </DialogDescription>
        </DialogHeader>

        <div className="flex-1 flex flex-col space-y-4 py-4 overflow-hidden">
          {/* Team Selection - Enhanced for mobile */}
          <div className="flex gap-2 px-1">
            <Select
              value={selectedTeam}
              onValueChange={setSelectedTeam}
              disabled={loading || availableTeams.length === 0}
            >
              <SelectTrigger className="w-full h-12">
                <SelectValue placeholder="Select a team to share with" />
              </SelectTrigger>
              <SelectContent className="z-[60]">
                {availableTeams.map((team) => (
                  <SelectItem 
                    key={team._id} 
                    value={team._id}
                    className="h-12 flex items-center"
                  >
                    <div className='flex items-center'>{team.isSeatsTeam && <Gem className='h-4 w-4 text-yellow-500 mr-1' />}{team.name}</div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button
              onClick={handleAddTeam}
              disabled={!selectedTeam || loading}
              className="h-12 w-12 p-0 flex items-center justify-center"
            >
              <Plus className="h-5 w-5" />
            </Button>
          </div>

          {/* Shared Teams List - Enhanced for mobile */}
          <div className="flex-1 min-h-0 space-y-3 px-1">
            <h4 className="text-sm font-medium flex items-center gap-2">
              <Users className="h-4 w-4" />
              Currently shared with:
            </h4>
            {sharedTeams.length === 0 ? (
              <div className="flex items-center justify-center h-32 border rounded-lg bg-secondary/10">
                <p className="text-sm text-muted-foreground text-center px-4">
                  Not shared with any teams yet
                </p>
              </div>
            ) : (
              <ScrollArea className="h-[40vh] rounded-lg border bg-white">
                <div className="p-2 space-y-2">
                  {sharedTeams.map((teamId) => {
                    let team;
                    if (!teamId.name) {
                      team = availableTeams.find(t => t._id === teamId);
                    } else {
                      team = teamId;
                    }
                    if (!team) return null;
                    
                    return (
                      <div
                        key={team._id}
                        className="flex items-center justify-between rounded-lg bg-gray-200 border py-0 lg:py-4 p-4"
                      >
                        <span className="text-base font-medium">
                          {team?.name || 'Unknown Team'}
                        </span>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleRemoveTeam(team._id)}
                          disabled={loading}
                          className="h-10 w-10 p-0"
                        >
                          <X className="h-5 w-5 text-muted-foreground hover:text-destructive" />
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </ScrollArea>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
    </div>
  );
};

export default TeamShareDialog;