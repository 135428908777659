// src/pages/ChatbotComponents/UserChatbotsView.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent } from '../../components/ui/dialog';
import { Input } from '../../components/ui/input';
import { Badge } from '../../components/ui/badge';
import { Loader2, Search, Bot } from 'lucide-react';
import ChatbotCard from './ChatbotCard';
import ChatbotDetails from './ChatbotDetails';

const UserChatbotsView = ({
  chatbots,
  loading,
  onChatbotSelect,
  selectedChatbotId,
  isMobileView
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const subscription = useSelector(state => state.auth.user?.subscription);

  const filteredChatbots = React.useMemo(() => {
    return chatbots.filter(chatbot =>
      chatbot.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      chatbot.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [chatbots, searchTerm]);

  const handleChatbotClick = (chatbot) => {
    onChatbotSelect(chatbot._id);
    if (isMobileView) {
      setShowDetailsDialog(true);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <>
      <div className="space-y-4 p-4">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Bot className="h-5 w-5 text-primary" />
              <h2 className="text-lg font-semibold">Your Chatbots</h2>
            </div>
            {subscription && (
              <Badge variant="outline" className="capitalize">
                {subscription.tier} Plan
              </Badge>
            )}
          </div>
          
          <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search your chatbots..."
              className="pl-8"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredChatbots.length > 0 ? (
            filteredChatbots.map(chatbot => (
              <ChatbotCard
                key={chatbot._id}
                chatbot={chatbot}
                isSelected={chatbot._id === selectedChatbotId}
                onClick={handleChatbotClick}
                showPurchaseOptions={false}
                className="h-full"
              />
            ))
          ) : (
            <div className="col-span-full text-center py-8 text-muted-foreground">
              {searchTerm ? (
                <>No chatbots found matching your search</>
              ) : (
                <>
                  <p className="mb-2">You haven't added any chatbots yet</p>
                  <p className="text-sm">
                    Visit the marketplace to discover and add chatbots to your collection
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {isMobileView && (
        <Dialog open={showDetailsDialog} onOpenChange={setShowDetailsDialog} className="mt-16">
          <DialogContent className="sm:max-w-[425px] mt-16">
            {selectedChatbotId && (
              <ChatbotDetails
                chatbotId={selectedChatbotId}
                onClose={() => setShowDetailsDialog(false)}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default UserChatbotsView;