// src/pages/Chatbots.js
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMarketplaceChatbots,
  fetchUserAddedChatbots,
  selectMarketplaceChatbots,
  selectUserChatbots,
  selectMarketplaceLoading,
  selectUserChatbotsLoading
} from '../slices/chatbotsSlice';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { ScrollArea } from '../components/ui/scroll-area';
import { Grid, PackageSearch, User } from 'lucide-react';
import MarketplaceView from './ChatbotComponents/MarketplaceView';
import UserChatbotsView from './ChatbotComponents/UserChatbotsView';
import ChatbotDetails from './ChatbotComponents/ChatbotDetails';
import MarketplaceFilters from './ChatbotComponents/MarketplaceFilters';
import { useLocation } from 'react-router-dom';

const Chatbots = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(state?.activeTab || 'marketplace');
  const [selectedChatbotId, setSelectedChatbotId] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);

  const marketplaceChatbots = useSelector(selectMarketplaceChatbots);
  const userChatbots = useSelector(selectUserChatbots);
  const marketplaceLoading = useSelector(selectMarketplaceLoading);
  const userChatbotsLoading = useSelector(selectUserChatbotsLoading);
  const ownedChatbotIds = useMemo(() => 
    new Set(userChatbots.map(bot => bot._id)),
    [userChatbots]
  );

  //console.log('ownedChatbotIds', ownedChatbotIds);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch(fetchMarketplaceChatbots());
    dispatch(fetchUserAddedChatbots());
  }, [dispatch]);

  const handleTabChange = (value) => {
    setActiveTab(value);
    setSelectedChatbotId(null);
  };

  const detailsRef = useRef(null);

  const handleChatbotSelect = (chatbotId) => {
    setSelectedChatbotId(chatbotId);
    if (!isMobileView && detailsRef.current) {
        detailsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    if (isMobileView) {
      // In mobile view, show details in a modal or new route
      // This will be handled by the child components
    }
  };

  return (
    <div className="h-[calc(100vh-4rem)] lg:h-screen container mx-auto p-2 lg:p-4 -mt-4 lg:-mt-0">
      <Tabs
        defaultValue="marketplace"
        value={activeTab}
        onValueChange={handleTabChange}
        className="w-full"
      >
        <div className="flex justify-between items-center mb-0 lg:mb-4">
          <TabsList>
            <TabsTrigger value="marketplace" className="flex items-center gap-2">
              <PackageSearch className="h-4 w-4" />
              Marketplace
            </TabsTrigger>
            <TabsTrigger value="my-chatbots" className="flex items-center gap-2">
              <User className="h-4 w-4" />
              My Chatbots
            </TabsTrigger>
          </TabsList>
          {/*<button
            className="lg:hidden"
            onClick={() => setIsMobileView(!isMobileView)}
          >
            <Grid className="h-6 w-6" />
          </button>*/}
        </div>

        <div className="mb-2 p-4 bg-muted/50 rounded-lg">
  <h2 className="text-lg font-semibold mb-1">Welcome to the Chatbot Marketplace</h2>
  <p className="text-sm text-muted-foreground">
    Browse and add AI chatbots to enhance your workspace. Discover free and premium assistants specialized in various tasks, or manage your existing collection in the My Chatbots tab.
  </p>
</div>

        <div className="flex flex-col lg:flex-row gap-4 h-[calc(100vh-8rem)] lg:h-full">
          <div className={`w-full ${selectedChatbotId && !isMobileView ? 'lg:w-2/3' : 'lg:w-full'}`} ref={detailsRef}>
            <TabsContent value="marketplace" className="m-0">
              <div className="flex flex-col h-full">
              <MarketplaceFilters 
  className="mb-4" 
  chatbots={marketplaceChatbots}
/>
                <ScrollArea className="flex-grow">
                  <MarketplaceView
                    chatbots={marketplaceChatbots}
                    loading={marketplaceLoading}
                    onChatbotSelect={handleChatbotSelect}
                    selectedChatbotId={selectedChatbotId}
                    isMobileView={isMobileView}
                    ownedChatbotIds={ownedChatbotIds}
                  />
                </ScrollArea>
              </div>
            </TabsContent>

            <TabsContent value="my-chatbots" className="m-0">
              <ScrollArea className="h-full">
                <UserChatbotsView
                  chatbots={userChatbots}
                  loading={userChatbotsLoading}
                  onChatbotSelect={handleChatbotSelect}
                  selectedChatbotId={selectedChatbotId}
                  isMobileView={isMobileView}
                />
              </ScrollArea>
            </TabsContent>
          </div>

          {selectedChatbotId && !isMobileView && (
            <div className="hidden lg:block w-1/3 h-full">
              <ScrollArea className="h-full">
                <ChatbotDetails
                  chatbotId={selectedChatbotId}
                  onClose={() => setSelectedChatbotId(null)}
                />
              </ScrollArea>
            </div>
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default Chatbots;