// File: /src/services/api.js

import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { store } from "../store";
import { refreshAuthToken, setLoading, clearAuth } from "../slices/authSlice";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;
let failedQueue = [];

// Timeout duration for waiting promises (in milliseconds)
const REFRESH_TIMEOUT = 3000; // 3 seconds

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

api.interceptors.request.use(
  async (config) => {
    if (
      config.data &&
      typeof config.data !== "string" &&
      !config.skipStringify
    ) {
      config.data = JSON.stringify(config.data);
      config.headers["Content-Type"] = "application/json";
    }

    if (!config.url.includes("/auth/refresh-token")) {
      const accessToken = localStorage.getItem("accessToken");

      if (accessToken) {
        let decoded;
        try {
          decoded = jwtDecode(accessToken);
        } catch (err) {
          // If decoding fails, clear tokens and reject immediately.
          store.dispatch(clearAuth());
          throw err;
        }
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime + 300) {
          // Refresh if token expires in less than 5 minutes
          if (!isRefreshing) {
            isRefreshing = true;
            store.dispatch(setLoading(true));

            try {
              await store.dispatch(refreshAuthToken()).unwrap();
              const newAccessToken = localStorage.getItem("accessToken");
              config.headers["Authorization"] = `Bearer ${newAccessToken}`;
              processQueue(null, newAccessToken);
            } catch (error) {
              processQueue(error, null);
              throw error;
            } finally {
              isRefreshing = false;
              store.dispatch(setLoading(false));
            }
          } else {
            // Wait for the ongoing refresh to complete, with a timeout.
          return new Promise((resolve, reject) => {
            const timer = setTimeout(() => {
              reject(new Error("Token refresh timeout"));
            }, REFRESH_TIMEOUT);
            failedQueue.push({
              resolve: (token) => {
                clearTimeout(timer);
                resolve(token);
              },
              reject: (err) => {
                clearTimeout(timer);
                reject(err);
              },
            });
          }).then(token => {
            config.headers['Authorization'] = `Bearer ${token}`;
            return config;
          }).catch(error => {
            return Promise.reject(error);
          });
        }
        } else {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;

        try {
          await store.dispatch(refreshAuthToken()).unwrap();
          const newAccessToken = localStorage.getItem('accessToken');
          api.defaults.headers['Authorization'] = `Bearer ${newAccessToken}`;
          processQueue(null, newAccessToken);
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          processQueue(refreshError, null);
          store.dispatch(clearAuth());
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      } else {
        // If a refresh is already in progress, wait for it to complete with a timeout.
        return new Promise((resolve, reject) => {
          const timer = setTimeout(() => {
            reject(new Error("Token refresh timeout"));
          }, REFRESH_TIMEOUT);
          failedQueue.push({
            resolve: (token) => {
              clearTimeout(timer);
              resolve(token);
            },
            reject: (err) => {
              clearTimeout(timer);
              reject(err);
            },
          });
        }).then(token => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return api(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }
    }

    return Promise.reject(error);
  }
);

export const fetchExecutionStatus = (executionId) =>
  api.get(`/executions/${executionId}/status`);
export const refreshToken = async (refreshToken) => {
  try {
    console.log("Sending refresh token:", refreshToken); // Log the token being sent
    const response = await api.post("/auth/refresh-token", { refreshToken });
    return response.data;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

export const checkEmailWhitelist = (email) =>
  api.post("/auth/check-whitelist", { email });
export const login = (credentials) => api.post("/auth/login", credentials);
export const refreshGoogleToken = () =>
  axios.post(
    `https://flow-orchestra-be-wyd5z.ondigitalocean.app/auth/google/refresh`
  );
export const getCurrentUser = () => api.get("/auth/me");
//export const updateCurrentUser = (userData) => api.put('/auth/me', userData);
//export const changePassword = (passwordData) => api.put('/auth/change-password', passwordData);
export const logout = () => api.post("/auth/logout");
export const register = (userData) => api.post("/auth/register", userData);
export const joinWaitlist = (email, listId) =>
  api.post("/subscribeUser", { email, listId });
export const getListCount = (listId) => api.get(`/getListCount/${listId}`);
export const forgotPassword = (data) =>
  api.post("/password/forgot-password", data);
export const resetPassword = (token, data) =>
  api.patch(`/password/reset-password/${token}`, data);

export const submitSurvey = (data) => api.post("/public/survey", data);
export const generateFlowStack = (data, teamId, stepLimit) =>
  api.post("/single-tasks/generate-flow-stack", { ...data, teamId, stepLimit });

// Audio track endpoints
export const getAudioTracks = (params) => api.get("/audio", { params });

export const getAudioTrackById = (id) => api.get(`/audio/${id}`);

export const getRecentTracks = (limit = 10) =>
  api.get(`/audio/recent/tracks?limit=${limit}`);

export const getPopularTracks = (limit = 10) =>
  api.get(`/audio/popular/tracks?limit=${limit}`);

export const getTracksByTag = (tag) => api.get(`/audio/tags/${tag}`);

export const fetchAllGlobalPromptStacks = () => api.get("/promptstack/global");

// Create a new prompt stack
export const createPromptStack = async (stackData) => {
  return api.post("/promptstack", stackData);
};

// Get all prompt stacks for the user
export const getPromptStacks = async (teamId) => {
  return api.get("/promptstack", {
    params: { ...(teamId !== "all" && { teamId }) },
  });
};

// Update an existing prompt stack
export const updatePromptStack = async (id, stackData) => {
  return api.put(`/promptstack/${id}`, stackData);
};

// Delete a prompt stack
export const deletePromptStack = async (id) => {
  return api.delete(`/promptstack/${id}`);
};

// Execute a prompt stack within a chat
export const executePromptStack = async (
  chatId,
  stackId = null,
  tempStack = null,
  promptCount = 0,
  teamId = null
) => {
  const endpoint = `/chats/${chatId}/executestack`;
  const payload = stackId
    ? { stackId, promptCount, teamId }
    : { tempStack, promptCount, teamId };
  return api.post(endpoint, payload);
};

// Single Task API calls
export const improvePrompt = (promptData) => {
  return api.post("/single-tasks/improve-prompt", promptData);
};

// Todo API calls
export const getTodos = (params) => {
  return api.get("/todos", { params });
};

export const getTeamTodos = (teamId, params) => {
  return api.get(`/todos/team/${teamId}`, { params });
};

export const createTodo = (todoData) => {
  return api.post("/todos", todoData);
};

export const updateTodo = (id, updates) => {
  return api.put(`/todos/${id}`, updates);
};

export const deleteTodo = (id) => {
  return api.delete(`/todos/${id}`);
};

export const updateTodoStatus = (id, status) => {
  return api.patch(`/todos/${id}/status`, { status });
};

export const toggleTodoArchive = (id) => {
  return api.patch(`/todos/${id}/archive`);
};

export const addTodoAttachment = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return api.post(`/todos/${id}/attachments`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    skipStringify: true,
  });
};

export const cloneTodo = (id) => {
  return api.post(`/todos/${id}/clone`);
};

export const updateTodoLabels = (id, labels) => {
  return api.patch(`/todos/${id}/labels`, { labels });
};

export const bulkArchiveTodos = (todoIds) => {
  return api.post("/todos/bulk-archive", { todoIds });
};

export const getTodoStats = (teamId = null) => {
  return api.get("/todos/stats", {
    params: teamId ? { teamId } : undefined,
  });
};

export const updateTodoChecklistItem = (todoId, itemIndex, completed) => {
  return api.patch(`/todos/${todoId}/checklist/${itemIndex}`, { completed });
};

// Subscription endpoints
export const getSubscriptionPlans = () => {
  return api.get("/subscriptions/plans");
};

export const getSubscriptionPlan = (id) => {
  return api.get(`/subscriptions/plans/${id}`);
};

export const createSubscriptionSession = (data) => {
  // Ensure subscriptionType has a default value
  const sessionData = {
    ...data,
    subscriptionType: data.subscriptionType || "individual",
  };

  return api
    .post("/subscriptions/create-session", sessionData)
    .then((response) => {
      if (!response.data?.sessionId) {
        console.error("Unexpected response structure:", response);
        throw new Error("Invalid server response");
      }
      return response;
    });
};

export const cancelSubscription = () => {
  return api.post("/subscriptions/cancel");
};

export const resumeSubscription = () => {
  return api.post("/subscriptions/resume");
};

// User profile endpoints
export const updateCurrentUser = (data) => {
  return api.patch("/user/update-profile", data);
};

export const changePassword = (data) => {
  return api.post("/user/change-password", data);
};

export const deleteUserAccount = (data) => {
  return api.delete("/user/delete-account", { data });
};

// Chatbot endpoints
export const getAllChatbots = (params) => {
  return api.get("/chatbots", { params });
};

export const getUserAddedChatbots = () => {
  return api.get("/chatbots/user/chatbots");
};

export const createChatbotCheckoutSession = (chatbotId) => {
  return api.post(`/marketplace/checkout/${chatbotId}`).then((response) => {
    if (!response.data?.sessionId) {
      console.error("Unexpected response structure:", response);
      throw new Error("Invalid server response");
    }
    return response;
  });
};

export const postAddFreeChatbot = (chatbotId) => {
  return api.post(`/marketplace/add-free-chatbot/${chatbotId}`);
};

export const toggleChatbotStatus = (chatbotId) => {
  return api.patch(`/chatbots/user/chatbots/${chatbotId}/toggle-status`);
};

// New API calls for action steps
export const postToInstagram = (savedPostId) =>
  api.post(`/actions/post-to-instagram/${savedPostId}`);
export const postToWordpress = (
  savedBlogpostId,
  { wordpressSiteUrl, postStatus, date }
) =>
  api.post(`/actions/postToWordpress/${savedBlogpostId}`, {
    wordpressSiteUrl,
    postStatus,
    date,
  });
export const postProductToWordpress = (
  savedProductId,
  { wordpressSiteUrl, listingDetails }
) =>
  api.post(`/actions/postProductToWordpress/${savedProductId}`, {
    wordpressSiteUrl,
    listingDetails,
  });
export const postCourseToTutorLMS = (
  savedCourseId,
  { wordpressSiteUrl, courseDetails }
) =>
  api.post(`/actions/postCourseToTutorLMS/${savedCourseId}`, {
    wordpressSiteUrl,
    courseDetails,
  });
export const saveAsset = (assetData) =>
  api.post("/actions/save-asset", assetData);

export const fetchItems = async (modelName) => {
  const response = await api.get(`/items/${modelName}`);
  return response.data;
};

// New image generation API calls
export const generateImage = (
  prompt,
  size,
  styleId,
  styleModelName,
  teamId = null
) => {
  return api
    .post("/images/generate", { prompt, size, styleId, styleModelName, teamId })
    .then((response) => {
      // Strip out non-serializable data
      const { headers, config, request, ...serializableData } = response.data;
      return serializableData;
    });
};

export const fetchUserImages = (page = 1, limit = 10) => {
  return api
    .get(`/images/history?page=${page}&limit=${limit}`)
    .then((response) => {
      // Strip out non-serializable data
      const { headers, config, request, ...serializableData } = response.data;
      return serializableData;
    });
};

export const deleteImage = (imageId) => {
  return api.delete(`/images/${imageId}`);
};

// Chat-related API calls
export const deleteChat = (chatId) => {
  return api.delete(`/chats/${chatId}`);
};

export const fetchChatHistory = (page = 1, limit = 10) =>
  api.get(`/chats?page=${page}&limit=${limit}`);

export const fetchChatById = (chatId) => {
  if (!chatId) {
    return Promise.reject(new Error("Chat ID is required"));
  }
  return api.get(`/chats/${chatId}/messages`).then((response) => {
    // Only for chat-related responses, strip out non-serializable data
    const { headers, config, request, ...serializableData } = response.data;
    return serializableData;
  });
};
export const createChat = (assistantId, title, assistantType, teamId = null) =>
  api.post("/chats", { assistantId, title, assistantType, teamId });
export const convertExecutionToChat = (executionId) =>
  api.post(`chats/execution/${executionId}/convert`);
export const sendMessage = (
  chatId,
  message,
  fileIds = [],
  assistantType,
  teamId = null
) =>
  api.post(`/chats/${chatId}/messages`, {
    message,
    fileIds,
    assistantType,
    teamId,
  });
//export const sendMessage = (chatId, message) => api.post(`/chats/${chatId}/messages`, { message });
export const uploadFile = (chatId, file) => {
  const formData = new FormData();
  formData.append("files", file);
  return api.post(`/chats/${chatId}/upload`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    skipStringify: true,
  });
};
export const switchAssistant = (chatId, assistantId, assistantType) =>
  api.put(`/chats/${chatId}/assistant`, { assistantId, assistantType });
export const updateTitle = (chatId, title) =>
  api.put(`/chats/${chatId}/title`, { title });

// UserChatbot API calls
export const getUserChatbots = () => api.get("/user-chatbots");
export const getUserChatbot = (id) => api.get(`/user-chatbots/${id}`);
export const createUserChatbot = (data) => api.post("/user-chatbots", data);
export const updateUserChatbot = (id, data) =>
  api.put(`/user-chatbots/${id}`, data);
export const deleteUserChatbot = (id) => api.delete(`/user-chatbots/${id}`);

// Flows
export const fetchFlows = ({
  page = 1,
  limit = 10,
  search = "",
  teamId = "all",
}) =>
  api.get("/flows", {
    params: {
      page,
      limit,
      search,
      ...(teamId !== "all" && { teamId }), // Only include teamId if it's not 'all'
    },
  });
export const fetchFlowById = (id) => api.get(`/flows/${id}`);
export const createFlow = (flowData) => api.post("/flows", flowData);
export const updateFlow = (id, flowData) => api.put(`/flows/${id}`, flowData);
export const deleteFlow = (id) => api.delete(`/flows/${id}`);
export const cloneFlow = (id) => api.post(`/flows/${id}/clone`);
export const fetchGlobalFlows = () => api.get("/flows/global");

// Executions
export const fetchExecutions = ({
  page = 1,
  limit = 50,
  teamId = "all",
  search = "",
  status = "all",
  sortBy = "startTime",
  sortOrder = "desc",
} = {}) =>
  api.get("/executions", {
    params: {
      page,
      limit,
      ...(teamId !== "all" && { teamId }),
      ...(search && { search }),
      ...(status !== "all" && { status }),
      sortBy,
      sortOrder,
    },
  });
export const fetchExecutionById = (id) => api.get(`/executions/${id}`);
export const startExecution = (flowId, teamId) =>
  api.post(`/executions/start/${flowId}`, { teamId });
export const cancelExecution = (id) => api.post(`/executions/${id}/cancel`);

// Schedules
export const fetchSchedules = () => api.get("/schedules");
export const createSchedule = (scheduleData) =>
  api.post("/schedules", scheduleData);
export const updateSchedule = (id, scheduleData) =>
  api.put(`/schedules/${id}`, scheduleData);
export const deleteSchedule = (id) => api.delete(`/schedules/${id}`);
export const activateSchedule = (id) => api.put(`/schedules/${id}/activate`);
export const deactivateSchedule = (id) =>
  api.put(`/schedules/${id}/deactivate`);
export const fetchSchedulesByFlow = (flowId) =>
  api.get(`/schedules/flow/${flowId}`);

// New API calls for saved assets
export const fetchSavedAssets = (assetType) => {
  if (!assetType) {
    throw new Error("Asset type is required");
  }
  return api.get(`/saved-assets/${assetType}`);
};
export const createSavedAsset = (assetType, assetData) =>
  api.post(`/saved-assets/${assetType}`, assetData);
export const updateSavedAsset = (assetType, id, assetData) =>
  api.put(`/saved-assets/${assetType}/${id}`, assetData);
export const deleteSavedAsset = (assetType, id) =>
  api.delete(`/saved-assets/${assetType}/${id}`);

export const renameTeam = (teamId, name) => {
  return api.put(`/teams/${teamId}/rename`, { name });
};

export const addTeamToRecord = (modelName, id, teamId) =>
  api.post(`/saved-assets/addTeamToRecord/${modelName}/${id}`, { teamId });

export const removeTeamFromRecord = (modelName, id, teamId) =>
  api.delete(`/saved-assets/removeTeamFromRecord/${modelName}/${id}/${teamId}`);

export const removeAllTeamsFromRecord = (modelName, id) =>
  api.delete(`/saved-assets/removeAllTeamsFromRecord/${modelName}/${id}`);

// New API calls for notifications
export const fetchNotifications = () => api.get("/notifications");
export const markNotificationAsRead = (id) =>
  api.put(`/notifications/${id}/read`);
export const markNotificationAsUnread = (id) =>
  api.put(`/notifications/${id}/unread`);
export const archiveNotification = (id) =>
  api.put(`/notifications/${id}/archive`);
export const deleteNotification = (id) => api.delete(`/notifications/${id}`);
export const deleteAllNotifications = () =>
  api.delete("/notifications/delete-all");
export const markAllNotificationsAsRead = () =>
  api.put("/notifications/read-all");
export const archiveAllNotifications = () =>
  api.put("/notifications/archive-all");
export const getUnreadNotificationCount = () =>
  api.get("/notifications/unread-count");

// Teams
export const fetchUserTeams = () => api.get("/teams");
export const createTeam = (teamData) => api.post("/teams", teamData);
export const joinTeamByCode = (teamCode) =>
  api.post("/teams/join-by-code", { teamCode });
export const joinTeam = (joinData) => api.post("/teams/join", joinData);
export const leaveTeam = (teamId) => api.delete(`/teams/${teamId}/leave`);
export const inviteUserToTeam = (teamId, inviteData) =>
  api.post(`/teams/${teamId}/invite`, inviteData);
export const createInvitation = (teamId, inviteData) =>
  api.post(`/teams/${teamId}/invite`, inviteData);
export const getInvitationByToken = (token) => api.get(`/invitations/${token}`);
export const acceptInvitation = (token) =>
  api.post(`/teams/invitations/${token}/accept`);
export const declineInvitation = (token) =>
  api.post(`/teams/invitations/${token}/decline`);
export const revokeInvitation = (teamId, invitationId) =>
  api.delete(`/teams/${teamId}/invitations/${invitationId}`);
export const updateMemberRole = (teamId, userId, role) =>
  api.put(`/teams/${teamId}/members/${userId}`, { role });
export const deleteTeam = (teamId) => api.delete(`/teams/${teamId}`);

// New function to fetch OpenAI Assistants
export const fetchAssistants = () => api.get("/flows/assistants");

export default api;
