import React, { useState } from 'react';
import { Card, CardHeader, CardContent, CardFooter } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import PricingHeader from "./PricingHeader";
import { Check } from 'lucide-react';

const PricingSection = ({ onStartFreePlan }) => {
  // Controls whether or not launch pricing is displayed.
  const [launchPricingEnabled, setLaunchPricingEnabled] = useState(true);

  // Controls the discount percentage if launch pricing is enabled.
  const [discountPercentage, setDiscountPercentage] = useState(25);

  // Utility function to format money nicely.
  const formatPrice = (priceNumber) => {
    // You can adjust this for region, currency, etc.
    // For example, using Intl.NumberFormat, or just a simple `$19` style.
    return `$${priceNumber}`;
  };

  // Given a numeric price, compute the discounted price if the discount is enabled.
  const getPriceToDisplay = (priceNumber) => {
    if (launchPricingEnabled && priceNumber > 0) {
      // Compute discounted price
      const discountedPrice = priceNumber - (priceNumber * (discountPercentage / 100));
      return formatPrice(discountedPrice);
    }
    // Otherwise, just return the standard price
    return formatPrice(priceNumber);
  };

  // Our plans will use a numeric `monthlyPrice` so we can do math on it.
  // If the plan is free, you can just make monthlyPrice = 0 or treat it specially.
  const plans = [
    {
      name: "Starter (Free)",
      monthlyPrice: 0,
      description: "per month",
      features: [
        "2 Active Flows",
        "5 Steps Per Flow",
        "250 Chat Messages",
        "50 Image Generations",
        "50 Flow Executions",
        "1 Custom Assistant",
        "1 Seat",
        "Basic Templates",
        "Unlimited Prompt Stacks"
      ]
    },
    {
      name: "Individual",
      monthlyPrice: 19,
      description: "per month",
      features: [
        "5 Active Flows",
        "5 Steps Per Flow",
        "100 Flow Executions",
        "500 Chat Messages",
        "5 Custom Assistants",
        "25 Image Generations",
        "1 Seat",
        "Advanced Templates",
        "Unlimited Prompt Stacks"
      ]
    },
    {
      name: "Growth",
      monthlyPrice: 49,
      description: "per month",
      features: [
        "10 Active Flows",
        "10 Steps Per Flow",
        "400 Flow Executions",
        "2000 Chat Messages",
        "10 Custom Assistants",
        "50 Image Generations",
        "Up to 3 Seats",
        "Advanced Templates",
        "Unlimited Prompt Stacks"
      ],
      popular: true
    },
    {
      name: "Creator",
      monthlyPrice: 99,
      description: "per month",
      features: [
        "Unlimited Active Flows",
        "50 Steps Per Flow",
        "1000 Flow Executions",
        "5000 Chat Messages",
        "Unlimited Custom Assistants",
        "150 Image Generations",
        "Up to 5 Seats",
        "Advanced Templates",
        "Unlimited Prompt Stacks"
      ]
    }
  ];

  return (
    <div className="min-h-screen flex flex-col justify-start items-center p-6 lg:p-12 bg-gray-50">
      <div className="pt-10 lg:pt-12 w-full max-w-7xl">
      {launchPricingEnabled && (
      <PricingHeader 
  launchPricingEnabled={true}
  discountPercentage={25}
/>
)}
        {/*<h2 className="text-3xl font-bold text-center mb-4">Simple, Transparent Pricing</h2>
        {launchPricingEnabled && (
                    <div className="max-w-2xl mx-auto mb-6 text-center">
                      <span className="inline-block border-2 border-red-600 text-black font-bold shadow-xl bg-gray-100 px-4 lg:px-8 py-2 lg:py-4 rounded text-center leading-tight">
                        Special Launch Pricing:<br className='sm:hidden'></br> For a limited time, we want to give you {discountPercentage}% OFF<br></br>your first full year with Flow Orchestra! 🤯
                      </span>
                    </div>
                  )}
        <p className="text-gray-600 text-center mb-12">Choose the plan that's right for you.</p>*/}
        

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pb-10 mt-10">
          {plans.map((plan, index) => {
            const showStrikeThrough = launchPricingEnabled && plan.monthlyPrice > 0;

            return (
              <Card 
                key={index}
                className={`relative transform transition-all duration-300 shadow-lg hover:scale-105 ${
                  plan.popular ? 'border-2 border-black' : ''
                }`}
              >
                {plan.popular && (
                  <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                    <span className="bg-black text-white px-4 py-1 rounded-full text-sm">
                      Most Popular
                    </span>
                  </div>
                )}

                <CardHeader className="p-4 lg:p-6">
                  <h3 className="text-lg lg:text-xl font-bold">{plan.name}</h3>
                  
                  {/* Conditionally show a "Special Launch Pricing" badge if discount is enabled and plan is not free */}
                  {launchPricingEnabled && plan.monthlyPrice > 0 && (
                    <div className="mt-2 mx-auto">
                      <span className="inline-block text-xs text-gray-100 border-2 border-red-500 bg-black px-6 py-2 font-bold rounded-xl text-center leading-tight">
                        Special Launch Pricing: {discountPercentage}% OFF<br></br>for your first 12 months! 🚀
                      </span>
                    </div>
                  )}

                  <div className="mt-2 lg:mt-4 flex items-baseline space-x-2">
                    {/* Show discounted price */}
                    <span className="text-3xl lg:text-4xl font-bold">
                      {plan.monthlyPrice > 0
                        ? getPriceToDisplay(plan.monthlyPrice)
                        : "Free"
                      }
                    </span>
                    
                    {/* If discount is enabled and plan has a price, show the original price in strikethrough */}
                    {showStrikeThrough && (
                      <span className="text-gray-500 line-through text-sm">
                        {formatPrice(plan.monthlyPrice)}
                      </span>
                    )}

                    <span className="text-gray-500 ml-2">{plan.description}</span>
                  </div>
                </CardHeader>

                <CardContent className="p-4 lg:p-6">
                  <ul className="space-y-2 lg:space-y-3 text-sm lg:text-base">
                    {plan.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center">
                        <Check className="h-4 w-4 lg:h-5 lg:w-5 text-green-500 mr-2 flex-shrink-0" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>

                <CardFooter className="p-4 lg:p-6">
              <Button 
                className={`w-full text-sm lg:text-base ${
                  plan.popular 
                    ? 'bg-black text-white hover:bg-gray-800' 
                    : 'bg-white text-black border-2 border-black hover:bg-gray-100'
                }`}
                onClick={onStartFreePlan}
              >
                Start For Free
              </Button>
            </CardFooter>
              </Card>
            );
          })}
        </div>
        <p className="text-gray-600 text-center mb-10">Start by creating your free account. Try Flow Orchestra out with your free credits. Upgrade your subscription within the app when you're ready.</p>
      </div>
    </div>
  );
};

export default PricingSection;