// src/pages/Scheduling.js
import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchSchedules, setSelectedSchedule } from '../slices/schedulesSlice';
import { Button } from '../components/ui/button';
import { Card } from '../components/ui/card';
import ScheduleList from './SchedulingComponents/ScheduleList';
import ScheduleDetails from './SchedulingComponents/ScheduleDetails';
import CreateScheduleForm from './SchedulingComponents/CreateScheduleForm';
import { fetchFlows } from "../slices/flowsSlice";
import { Cog, Menu, X } from 'lucide-react';

const Scheduling = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { scheduleId } = useParams();
  const { schedules, selectedSchedule, status, error } = useSelector((state) => state.schedules);
  const { selectedTeam } = useSelector((state) => state.teams);
  const { flows } = useSelector((state) => state.flows);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);

  const refreshSchedules = useCallback(() => {
    dispatch(fetchSchedules());
  }, [dispatch]);

  useEffect(() => {
    refreshSchedules();
  }, [refreshSchedules]);

  useEffect(() => {
    if (scheduleId) {
      const schedule = schedules.find((s) => s._id === scheduleId);
      dispatch(setSelectedSchedule(schedule));
    } else {
      dispatch(setSelectedSchedule(null));
    }
  }, [scheduleId, schedules, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(fetchFlows({ teamId: selectedTeam })).unwrap(),
        ]);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, [dispatch, selectedTeam]);

  const handleCreateSchedule = () => {
    navigate('/scheduling/create');
    setIsMobileMenuOpen(false);
  };

  if (status === 'failed') {
    return <div className="text-center text-red-500">Error: {error}</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row h-[calc(100vh-4rem)] lg:h-[calc(100vh-2rem)]">
      {/* Mobile Header */}
      <div className="lg:hidden flex items-center px-4 border-b bg-background z-[60] -mt-4 -mx-6">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="mr-2"
        >
          <Cog className="h-5 w-5" />
        </Button>
        <h2 className="text-base font-medium truncate">
          {!isMobileMenuOpen && (selectedSchedule ? selectedSchedule.flow.name : 'Schedules')}
        </h2>
      </div>

      {/* Schedule List Panel */}
      <div 
        className={`
          w-full lg:w-1/3 bg-white
          fixed lg:relative inset-y-0 left-0 z-[70]
          transform transition-transform duration-200 ease-in-out
          lg:transform-none h-[100vh] lg:h-auto pt-0 lg:mt-4
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
        `}
      >
        <Card className="h-full px-4 lg:p-4 overflow-y-auto border-none shadow-md">
          <div className="flex justify-between items-center mb-4 pt-4 lg:pt-0">
            <h2 className="font-bold text-xl">Schedules</h2>
            <div className="flex items-center gap-2">
              <Button className="text-gray-100" onClick={handleCreateSchedule}>Create Schedule</Button>
              <Button
                variant="ghost"
                size="sm"
                className="lg:hidden"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
          </div>
          <ScheduleList 
            schedules={schedules} 
            selectedScheduleId={selectedSchedule?._id} 
            onSelect={() => setIsMobileMenuOpen(false)}
          />
        </Card>
      </div>

      {/* Schedule Details/Form Panel */}
      <div className="flex-1 lg:w-2/3 overflow-y-auto px-4 pt-0 lg:pt-4 mt-4 lg:mt-0 -mx-6 lg:mx-0">
        <Card className="p-4 border-none shadow-md">
          {scheduleId === 'create' ? (
            <CreateScheduleForm />
          ) : selectedSchedule ? (
            <ScheduleDetails schedule={selectedSchedule} onUpdate={refreshSchedules} />
          ) : (
            <div className="text-center text-gray-500">Select a schedule to view details</div>
          )}
        </Card>
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div 
          className="fixed inset-0 bg-black/50 z-[65] lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </div>
  );
};

export default Scheduling;