import React, { useEffect, useState, useCallback, memo, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchExecutionById,
  clearCurrentExecution,
  fetchExecutions,
  fetchExecutionStatus,
  updateExecutionInList,
} from "../slices/executionsSlice";
import { Card, CardHeader, CardContent } from "../components/ui/card";
import { Pagination } from "../components/ui/pagination";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { ScrollArea } from "../components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Badge } from "../components/ui/badge";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../components/ui/tooltip";
import {
  CheckCircle,
  XCircle,
  Clock,
  AlertTriangle,
  Search,
  Menu,
  X,
  Cog,
  Play,
} from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import ExecutionDetails from "./ExecutionPageComponents/ExecutionDetails";
import { openVideoModal } from "../slices/videoModalSlice";

const ExecutionListItem = memo(({ execution, isSelected, onSelect }) => (
  <Card
    className={`cursor-pointer transition-colors bg-gray-100 hover:bg-gray-200 shadow-md ${
      isSelected ? "bg-black text-white" : ""
    } mb-2`}
    onClick={() => onSelect(execution)}
  >
    <CardContent className="p-4">
      <div className="flex justify-between items-center">
        <h3 className="text-sm font-semibold">{execution.flow?.name}</h3>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Badge
                variant={
                  execution.status === "completed"
                    ? "success"
                    : execution.status === "failed"
                    ? "destructive"
                    : "default"
                }
              >
                {execution.status === "completed" && (
                  <CheckCircle className="w-3 h-3 mr-1" />
                )}
                {execution.status === "failed" && (
                  <XCircle className="w-3 h-3 mr-1" />
                )}
                {execution.status === "running" && (
                  <Clock className="w-3 h-3 mr-1" />
                )}
                {execution.status}
              </Badge>
            </TooltipTrigger>
            <TooltipContent>Execution status</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <p className="text-xs text-muted-foreground mt-1">
        Started:{" "}
        {formatDistanceToNow(new Date(execution.startTime), {
          addSuffix: true,
        })}
      </p>
      <p className="text-xs text-muted-foreground">
        Duration:{" "}
        {execution.duration
          ? `${(execution.duration / 1000).toFixed(2)}s`
          : "N/A"}
      </p>
    </CardContent>
  </Card>
));

const ExecutionList = memo(
  ({ executions, onSelectExecution, selectedExecutionId }) => (
    <div className="space-y-2 p-4">
      {executions.map((execution) => (
        <ExecutionListItem
          key={execution._id}
          execution={execution}
          isSelected={selectedExecutionId === execution._id}
          onSelect={onSelectExecution}
        />
      ))}
    </div>
  )
);

const ExecutionPage = () => {
  const { executionId } = useParams();
  console.log("Execution ID:", executionId);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [sortBy, setSortBy] = useState("startTime");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20); // Assuming 10 items per page
  const [totalItems, setTotalItems] = useState(0);
  const [isPolling, setIsPolling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [executionStatus, setExecutionStatus] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);

  const execution = useSelector((state) => state.executions.currentExecution);
  const { executions, totalExecutions } = useSelector(
    (state) => state.executions
  );
  const { selectedTeam } = useSelector((state) => state.teams);

  const prevExecutionStatusRef = useRef();

  const fetchStatus = useCallback(() => {
    if (executionId) {
      dispatch(fetchExecutionStatus(executionId))
        .unwrap()
        .then((updatedExecution) => {
          if (updatedExecution.status !== "running") {
            setIsPolling(false);
          }
          if (updatedExecution.status !== prevExecutionStatusRef.current) {
            dispatch(updateExecutionInList(updatedExecution));
            prevExecutionStatusRef.current = updatedExecution.status;
          }
        });
    }
  }, [dispatch, executionId]);

  useEffect(() => {
    let intervalId;
    if (isPolling && execution?.status === "running") {
      intervalId = setInterval(fetchStatus, 5000);
      setExecutionStatus(execution?.status);
    } else if (execution?.status !== "running") {
      setIsPolling(false);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isPolling, fetchStatus, execution]);

  useEffect(() => {
    if (executionId) {
      setIsLoading(true);
      dispatch(fetchExecutionById(executionId))
        .unwrap()
        .then((fetchedExecution) => {
          setIsLoading(false);
          if (fetchedExecution.status === "running") {
            setIsPolling(true);
          }
          prevExecutionStatusRef.current = fetchedExecution.status;
        })
        .catch((error) => {
          console.error("Error fetching execution:", error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setIsPolling(false);
    }

    return () => {
      dispatch(clearCurrentExecution()); // Clear execution when component unmounts
    };
  }, [dispatch, executionId]);

  useEffect(() => {
    dispatch(
      fetchExecutions({
        page: currentPage,
        limit: itemsPerPage,
        teamId: selectedTeam,
        search: searchTerm,
        status: statusFilter,
        sortBy,
        sortOrder,
      })
    );
    setTotalItems(totalExecutions);
    console.log("Executions:", executions);

    if (executionId) {
      dispatch(fetchExecutionById(executionId));
    }

    return () => {
      dispatch(clearCurrentExecution());
    };
  }, [
    dispatch,
    executionId,
    currentPage,
    itemsPerPage,
    selectedTeam,
    searchTerm,
    statusFilter,
    sortBy,
    sortOrder,
  ]);

  const handleSelectExecution = useCallback(
    (execution) => {
      if (execution._id !== executionId) {
        setIsLoading(true);
        dispatch(clearCurrentExecution()); // Clear current state before navigation
        navigate(`/execution/${execution._id}`); // Navigate to the new execution's page
      }
    },
    [executionId, navigate, dispatch]
  );

  const handleOpenSingleVideo = () => {
    dispatch(
      openVideoModal({
        url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Flow%20form%20Scratch%20%20-%20Explainer%20Vid.mp4",
        title: "How to Build a Flow from Scratch",
      })
    );
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredExecutions = executions.filter((exec) => {
    const matchesSearch = exec.flow?.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesStatus =
      statusFilter === "all" || exec.status === statusFilter;
    return matchesSearch && matchesStatus;
  });

  const sortedExecutions = [...filteredExecutions].sort((a, b) => {
    if (sortBy === "startTime") {
      return sortOrder === "asc"
        ? new Date(a.startTime) - new Date(b.startTime)
        : new Date(b.startTime) - new Date(a.startTime);
    } else if (sortBy === "duration") {
      return sortOrder === "asc"
        ? a.duration - b.duration
        : b.duration - a.duration;
    }
    return 0;
  });

  return (
    <div className="flex flex-col lg:flex-row h-[calc(100vh-4rem)] lg:h-[calc(100vh-0rem)]">
      {/* Mobile Header */}
      <div className="lg:hidden flex items-center px-4 border-b bg-background z-[60] -mx-6 -mt-4">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="mr-2"
        >
          <Cog className="h-5 w-5" />
        </Button>
        <h2 className="text-base font-medium truncate">
          {!isMobileMenuOpen &&
            (execution ? execution.flow?.name : "Executions")}
        </h2>
      </div>

      {/* Executions List Panel */}
      <div
        className={`
    w-full lg:w-1/3 border-r bg-background
    fixed lg:relative inset-y-0 left-0 z-[70] lg:z-[60]
    transform transition-transform duration-200 ease-in-out
    lg:transform-none h-[100vh] lg:h-auto pt-0
    ${isMobileMenuOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0"}
  `}
      >
        <div className="flex flex-col h-full">
          <div className="p-4 border-b">
            <div className="flex flex-col lg:flex-row justify-between items-center mb-4">
              <div className="flex items-center justify-between w-full">
                <h1 className="text-xl font-semibold">Executions</h1>
                <Button
                  variant="ghost"
                  size="sm"
                  className="lg:hidden"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <X className="h-5 w-5" />
                </Button>
              </div>
              <Button
                variant="outline"
                size="sm"
                className="group text-black border-none hover:text-gray-800 hover:bg-gray-200 transition-colors duration-100 w-full lg:w-fit bg-gray-100 shadow-md"
                onClick={handleOpenSingleVideo}
              >
                <div className="flex items-center">
                  <Play className="h-4 w-4 mr-2 text-black group-hover:text-gray-800" />
                  How to Build a Flow from Scratch
                </div>
              </Button>
            </div>
            <div className="space-y-3">
              <Input
                type="text"
                placeholder="Search executions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full"
                icon={<Search className="h-4 w-4" />}
              />
              <div className="flex gap-2">
                <Select value={statusFilter} onValueChange={setStatusFilter}>
                  <SelectTrigger className="w-1/3 bg-gray-100 shadow-md">
                    <SelectValue placeholder="Status" />
                  </SelectTrigger>
                  <SelectContent className="z-[70]">
                    <SelectItem value="all">All Statuses</SelectItem>
                    <SelectItem value="completed">Completed</SelectItem>
                    <SelectItem value="failed">Failed</SelectItem>
                    <SelectItem value="running">Running</SelectItem>
                  </SelectContent>
                </Select>
                <Select value={sortBy} onValueChange={setSortBy}>
                  <SelectTrigger className="w-1/3 bg-gray-100 shadow-md">
                    <SelectValue placeholder="Sort By" />
                  </SelectTrigger>
                  <SelectContent className="z-[70]">
                    <SelectItem value="startTime">Start Time</SelectItem>
                    <SelectItem value="duration">Duration</SelectItem>
                  </SelectContent>
                </Select>

                <Select value={sortOrder} onValueChange={setSortOrder}>
                  <SelectTrigger className="w-1/3 bg-gray-100 shadow-md">
                    <SelectValue placeholder="Sort Order" />
                  </SelectTrigger>
                  <SelectContent className="z-[70]">
                    <SelectItem value="asc">Ascending</SelectItem>
                    <SelectItem value="desc">Descending</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto">
            <ExecutionList
              executions={sortedExecutions}
              onSelectExecution={(execution) => {
                handleSelectExecution(execution);
                setIsMobileMenuOpen(false);
              }}
              selectedExecutionId={executionId}
            />
          </div>

          <div className="p-4 border-t mt-auto">
            <div className="flex flex-col lg:flex-row gap-2 items-center justify-between">
              <Select
                value={itemsPerPage.toString()}
                onValueChange={(value) => setItemsPerPage(Number(value))}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Items per page" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="10">10 per page</SelectItem>
                  <SelectItem value="20">20 per page</SelectItem>
                  <SelectItem value="50">50 per page</SelectItem>
                  <SelectItem value="100">100 per page</SelectItem>
                </SelectContent>
              </Select>
              <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                paginate={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Execution Details Panel */}
      <div className="flex-1 lg:w-2/3 overflow-y-auto pt-0 lg:pt-4">
        {isLoading ? (
          <div className="h-full flex items-center justify-center">
            <p className="text-muted-foreground">
              Loading execution details...
            </p>
          </div>
        ) : execution ? (
          <div className="py-4">
            <ExecutionDetails
              execution={execution}
              isPolling={isPolling}
              setIsPolling={setIsPolling}
            />
          </div>
        ) : (
          <div className="h-full flex items-center justify-center">
            <p className="text-muted-foreground">
              Select an execution to view details
            </p>
          </div>
        )}
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-[65] lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </div>
  );
};

export default ExecutionPage;
