import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'http://localhost:5000';
let socket;

export const initSocket = (accessToken) => {
  console.log('Initializing socket connection');
  //console.log('SOCKET_URL:', SOCKET_URL);
  
  // Close existing socket if it exists
  if (socket) {
    console.log('Closing existing socket connection');
    socket.close();
  }
  
  socket = io(SOCKET_URL, {
    auth: { token: accessToken },
    transports: ['websocket'],
    forceNew: true,
    withCredentials: true, // Enable credentials for session support
    reconnection: true, // Enable reconnection
    reconnectionAttempts: 5, // Maximum number of reconnection attempts
    reconnectionDelay: 1000, // Time between reconnection attempts (ms)
    reconnectionDelayMax: 5000, // Maximum reconnection delay
    timeout: 20000, // Connection timeout
    autoConnect: true, // Automatically connect on creation
  });

  // Connection event handlers
  socket.on('connect', () => {
    console.log('Connected to socket server');
    //console.log('Socket ID:', socket.id);
  });

  socket.on('disconnect', (reason) => {
    console.log('Disconnected from socket server:', reason);
    // Handle different disconnect reasons
    if (reason === 'io server disconnect') {
      // Server initiated disconnect, attempt reconnection
      socket.connect();
    }
  });

  socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error);
    console.error('Error details:', error.message);
    
    // Handle specific error types
    if (error.message === 'Authentication error') {
      console.error('Authentication failed. Please check your token.');
    }
  });

  socket.on('reconnect', (attemptNumber) => {
    console.log(`Reconnected after ${attemptNumber} attempts`);
  });

  socket.on('reconnect_attempt', (attemptNumber) => {
    console.log(`Reconnection attempt ${attemptNumber}`);
  });

  socket.on('reconnect_error', (error) => {
    console.error('Reconnection error:', error);
  });

  socket.on('reconnect_failed', () => {
    console.error('Failed to reconnect after maximum attempts');
  });

  // Error event handler
  socket.on('error', (error) => {
    console.error('Socket error:', error);
  });

  return socket;
};

export const getSocket = () => {
  if (!socket) {
    console.warn('Socket not initialized. Call initSocket first.');
    return null;
  }
  
  // Check if socket is connected
  if (!socket.connected) {
    console.warn('Socket is disconnected. Attempting to reconnect...');
    socket.connect();
  }
  
  return socket;
};

export const closeSocket = () => {
  if (socket) {
    console.log('Closing socket connection');
    // Remove all listeners before disconnecting
    socket.removeAllListeners();
    socket.disconnect();
    socket = null;
  } else {
    console.warn('No socket connection to close');
  }
};

// Helper function to check socket connection status
export const isSocketConnected = () => {
  return socket?.connected ?? false;
};

// Helper function to manually reconnect
export const reconnectSocket = () => {
  if (socket) {
    console.log('Attempting manual reconnection...');
    socket.connect();
  } else {
    console.warn('No socket instance exists to reconnect');
  }
};

/*import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'http://localhost:5000';
let socket;

export const initSocket = (accessToken) => {
  console.log('Initializing socket connection');
  console.log('SOCKET_URL:', SOCKET_URL);
  
  socket = io(SOCKET_URL, {
    auth: { token: accessToken },
    transports: ['websocket'],
    forceNew: true
  });

  socket.on('connect', () => {
    console.log('Connected to socket server');
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from socket server');
  });

  socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error);
    console.error('Error details:', error.message);
  });

  return socket;
};

export const getSocket = () => {
  if (!socket) {
    console.warn('Socket not initialized. Call initSocket first.');
    return null;
  }
  return socket;
};

export const closeSocket = () => {
  if (socket) {
    console.log('Closing socket connection');
    socket.disconnect();
    socket = null;
  } else {
    console.warn('No socket connection to close');
  }
};*/